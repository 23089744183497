import { mapState } from 'vuex';
import { deliveryMethods, newPostMethods, pickupMethods } from '$modules/shipping/config';
import ShippingControlsMixin from '$modules/shipping/mixins/shipping-controls-mixin';

export default {
  name: 'ProductAvailableMixin',
  mixins: [ShippingControlsMixin],
  computed: {
    ...mapState({
      currentShipping: state => state['shipping-module'].current,
      currentRegion: state => state['shipping-module'].currentRegion,
      shopDeliveryPolygon: state => state['shipping-module'].shopDeliveryPolygon
    }),
    sqpp () {
      return this.product.sqpp
    },
    openTab () {
      return this.shopDeliveryPolygon ? 'DELIVERY' : 'PICKUP'
    },
    productLabel () {
      if (!this.currentShipping) return this.productLabelCountry

      if (pickupMethods.includes(this.currentShipping.method)) {
        return this.productLabelPickup
      }

      if (deliveryMethods.includes(this.currentShipping.method)) {
        return this.productLabelDelivery
      }

      if (this.shopDeliveryPolygon && newPostMethods.includes(this.currentShipping.method)) {
        return this.productLabelNPDelivery
      }

      if (newPostMethods.includes(this.currentShipping.method)) {
        return this.productLabelNP
      }

      if (this.currentRegion?.id) {
        return this.productLabelRegion
      }

      return this.productLabelCountry
    },
    productLabelCountry () {
      // if (
      //   this.sqpp?.availability?.delivery &&
      //   this.sqpp?.availability?.pickup &&
      //   !this.sqpp?.availability?.shipping
      // ) {
      //   return {
      //     label: 'Label only delivery shop',
      //     openTab: this.openTab,
      //     availability: true,
      //     addToCart: true
      //   }
      // }

      if (
        this.sqpp?.availability?.delivery &&
        this.sqpp?.availability?.pickup
      ) {
        return {
          label: '',
          openTab: this.openTab,
          availability: false,
          addToCart: true
        }
      }

      if (
        this.sqpp?.availability?.pickup
      ) {
        return {
          label: 'Label only pickup',
          openTab: this.openTab,
          availability: true,
          addToCart: true
        }
      }

      if (!this.isOutOfStock) {
        return {
          label: false,
          openTab: this.openTab,
          availability: false,
          addToCart: true
        }
      }

      return {
        label: 'The product is out of stock',
        availability: false,
        addToCart: false
      }
    },
    productLabelRegion () {
      if (
        (
          this.sqpp?.availability?.pickup &&
          !this.sqpp?.availability?.delivery &&
          this.sqpp?.availability?.shipping
        )
      ) {
        return {
          label: 'Label only shipping and pickup',
          openTab: this.openTab,
          availability: true,
          addToCart: true
        }
      }

      if (
        (
          this.shopDeliveryPolygon &&
          this.sqpp?.availability?.pickup &&
          this.sqpp?.availability?.delivery &&
          !this.sqpp?.availability?.shipping
        )
      ) {
        return {
          label: 'Label only delivery shop',
          openTab: this.openTab,
          availability: true,
          addToCart: true
        }
      }

      if (
        (
          this.sqpp?.availability?.delivery &&
          this.sqpp?.availability?.pickup
        ) || (
          !this.shopDeliveryPolygon &&
          this.sqpp?.availability?.pickup &&
          this.sqpp?.availability?.shipping
        )
      ) {
        return {
          label: '',
          openTab: this.openTab,
          availability: false,
          addToCart: true
        }
      }

      if (
        this.sqpp?.availability?.pickup
      ) {
        return {
          label: 'Label only pickup',
          openTab: this.openTab,
          availability: true,
          addToCart: true
        }
      }

      if (
        !this.sqpp?.availability?.shipping &&
        !this.sqpp?.availability?.other_market &&
        this.sqpp?.availability?.other_regions
      ) {
        return {
          label: 'Label another region',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      if (this.sqpp?.availability?.shipping) {
        return {
          label: 'Label only shipping',
          openTab: 'DELIVERY_NOVA',
          availability: true,
          addToCart: true
        }
      }

      return {
        label: 'The product is out of stock',
        availability: false,
        addToCart: false
      }
    },
    productLabelDelivery () {
      if (
        this.sqpp?.in_stock &&
        this.sqpp?.availability?.delivery &&
        this.sqpp?.availability?.pickup
      ) {
        return {
          label: '',
          openTab: this.openTab,
          availability: false,
          addToCart: true
        }
      }

      if (
        this.sqpp?.availability?.pickup &&
        this.sqpp?.availability?.other_market
      ) {
        return {
          label: 'Label only pickup',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      if (
        this.sqpp?.availability?.other_market &&
        this.sqpp?.availability?.shipping
      ) {
        return {
          label: 'Label only shipping and pickup',
          openTab: 'DELIVERY_NOVA',
          availability: true,
          addToCart: false
        }
      }

      if (
        this.sqpp?.availability?.shipping &&
        !this.sqpp?.availability?.pickup &&
        !this.sqpp?.availability?.delivery
      ) {
        return {
          label: 'Label only shipping',
          openTab: 'DELIVERY_NOVA',
          availability: true,
          addToCart: false
        }
      }

      if (
        this.sqpp?.availability?.other_regions &&
        !this.sqpp?.availability?.other_market
      ) {
        return {
          label: 'Label another region',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      if (
        this.sqpp?.availability?.other_market
      ) {
        return {
          label: 'Label only pickup',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      return {
        label: 'The product is out of stock',
        availability: false,
        addToCart: false
      }
    },
    productLabelPickup () {
      // if (
      //   !this.shopDeliveryPolygon &&
      //   this.sqpp?.availability?.pickup &&
      //   this.sqpp?.availability?.shipping &&
      //   !this.sqpp?.availability?.delivery
      // ) {
      //   return {
      //     label: 'Label only pickup',
      //     openTab: this.openTab,
      //     availability: true,
      //     addToCart: true
      //   }
      // }

      if (
        (
          this.sqpp?.availability?.pickup &&
          this.sqpp?.availability?.shipping &&
          !this.sqpp?.availability?.delivery
        ) || (
          this.sqpp?.availability?.pickup &&
          this.sqpp?.availability?.delivery
        ) || (
          !this.shopDeliveryPolygon &&
          this.sqpp?.availability?.pickup &&
          this.sqpp?.availability?.shipping
        ) || (
          this.sqpp?.in_stock
        )
      ) {
        return {
          label: '',
          openTab: this.openTab,
          availability: false,
          addToCart: true
        }
      }

      if (
        !this.sqpp?.availability?.pickup &&
        !this.sqpp?.availability?.delivery &&
        this.sqpp?.availability?.shipping &&
        this.sqpp?.availability?.other_market
      ) {
        return {
          label: 'Label should shipping',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      if (
        !this.sqpp?.availability?.pickup &&
        !this.sqpp?.availability?.delivery &&
        this.sqpp?.availability?.shipping
      ) {
        return {
          label: 'Label only shipping',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      if (
        !this.sqpp?.availability?.pickup &&
        !this.sqpp?.availability?.delivery &&
        !this.sqpp?.availability?.shipping &&
        this.sqpp?.availability?.other_market
      ) {
        return {
          label: 'Label another market',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      if (
        this.sqpp?.availability?.shipping &&
        !this.sqpp?.availability?.delivery &&
        !this.sqpp?.availability?.pickup &&
        !this.sqpp?.availability?.other_market
      ) {
        return {
          label: 'Label only shipping',
          openTab: 'DELIVERY_NOVA',
          availability: true,
          addToCart: false
        }
      }

      if (
        this.shopDeliveryPolygon &&
        this.sqpp?.availability?.delivery &&
        !this.sqpp?.availability?.shipping &&
        this.sqpp?.availability?.other_market
      ) {
        return {
          label: 'Label should delivery',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      if (
        this.shopDeliveryPolygon &&
        this.sqpp?.availability?.shipping &&
        this.sqpp?.availability?.other_market
      ) {
        return {
          label: 'Label should shipping',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      if (
        this.sqpp?.availability?.other_regions
      ) {
        return {
          label: 'Label another region',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      return {
        label: 'The product is out of stock',
        availability: false,
        addToCart: false
      }
    },
    productLabelNPDelivery () {
      if (
        (
          this.sqpp?.availability?.shipping &&
          this.sqpp?.availability?.delivery &&
          this.sqpp?.availability?.pickup
        ) || (
          this.sqpp?.availability?.shipping &&
          !this.sqpp?.availability?.delivery &&
          !this.sqpp?.availability?.pickup
        )
      ) {
        return {
          label: '',
          openTab: this.openTab,
          availability: false,
          addToCart: true
        }
      }

      if (
        !this.sqpp?.availability?.shipping &&
        this.sqpp?.availability?.delivery &&
        this.sqpp?.availability?.pickup
      ) {
        return {
          label: 'Label only delivery shop',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      if (
        !this.sqpp?.availability?.shipping &&
        !this.sqpp?.availability?.delivery &&
        this.sqpp?.availability?.pickup
      ) {
        return {
          label: 'Label only pickup',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      if (
        !this.sqpp?.availability?.shipping &&
        this.sqpp?.availability?.other_market
      ) {
        return {
          label: 'Label only pickup',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      if (
        !this.sqpp?.availability?.shipping &&
        this.sqpp?.availability?.other_regions
      ) {
        return {
          label: 'Label another region',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      return {
        label: 'The product is out of stock',
        availability: false,
        addToCart: false
      }
    },
    productLabelNP () {
      if (
        this.sqpp?.availability?.shipping
      ) {
        return {
          label: '',
          openTab: this.openTab,
          availability: false,
          addToCart: true
        }
      }

      if (
        !this.sqpp?.availability?.shipping &&
        this.sqpp?.availability?.other_regions
      ) {
        return {
          label: 'Label another region',
          openTab: this.openTab,
          availability: true,
          addToCart: false
        }
      }

      return {
        label: 'The product is out of stock',
        availability: false,
        addToCart: false
      }
    }
  },
  methods: {
    openAvailability () {
      this.openShippingModal({
        openTab: this.productLabel?.openTab || null
      })
    }
  }
};
