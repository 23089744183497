import { getCurrentRegion } from 'theme/store/checkout/helpers';
import { isRegionalPage } from '$modules/shipping/utils/region-url';
import i18n from '@vue-storefront/i18n'

export function regionMeta (route: string, h1: string) {
  const region = getCurrentRegion()

  if (!isRegionalPage(route, region)) return {}

  const name: string = region?.name_locative

  if (!name) return {}

  return {
    h1: i18n.t('META_REGION_H1', { h1, region: name }),
    title: i18n.t('META_REGION_TITLE', { h1, region: name }),
    description: i18n.t('META_REGION_DESCRIPTION', { h1, region: name }),
    region
  }
}
