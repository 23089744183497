import { RobotsEnum } from './types'
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { relRobotsHandler } from 'theme/meta/utils/robots';
import config from 'config';
import { getThumbnailPath } from '@vue-storefront/core/helpers';
import { regionMeta } from 'theme/meta/utils/region';
import { isRegionalPage } from '$modules/shipping/utils/region-url';
import i18n from '@vue-storefront/i18n'

export function getMetaContent (current, route) {
  const metaTitle = current?.meta_title || current?.name || ''
  const metaDescription = current?.meta_description || ''

  const regionalMeta = regionMeta(route?.fullPath, metaTitle)

  return {
    title: regionalMeta?.title || metaTitle || `${i18n.t('Stock')} | VARUS`,
    description: regionalMeta?.description || metaDescription || `${i18n.t('Stock')} ${i18n.t('OTHER_PAGES_DESCRIPTION')}`,
    region: regionalMeta?.region
  }
}

export function metaPromotionPage () {
  let relRobots = relRobotsHandler(RobotsEnum.NOINDEX, config.xEnv.key);
  const bannerMobilePath = getThumbnailPath(this.getCurrentPage?.mobile_image)
  const bannerDesktopPath = getThumbnailPath(this.getCurrentPage?.desktop_image)

  const metaContent = getMetaContent(this.getCurrentPage, this.$route)

  if (isRegionalPage(this.$route.fullPath) && !metaContent?.region?.is_indexed) {
    relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);
  }

  const meta = {
    title: metaContent.title,
    meta: {
      ...relRobots,
      description: {
        name: 'description',
        content: metaContent.description
      }
    },
    link: [
      {
        rel: 'preload',
        href: bannerMobilePath || bannerDesktopPath || '',
        as: 'image'
      }
    ]
  };

  return mergeMeta(meta)
}
